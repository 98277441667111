import { initializeIterable } from "Components/Utils/IterableService";
import { useEffect } from "react";

function useNotifications(id, email) {
  useEffect(() => {
    if (id && email) {
      const initNotifications = async () => {
        await initializeIterable(email);
      };

      initNotifications();
    }
  }, [id, email]);
}

export default useNotifications;
