import config from "Config/config";
import { iterableJwtAuthentication } from "API/restAPI";
import { getInAppMessages, initializeWithConfig } from "@iterable/web-sdk";

import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { analytics } from "Analytics/index";

export async function initializeIterable(email) {
  const initializeParams = {
    authToken: config.iterable.apiKey,
    configOptions: {
      isEuIterableService: false,
      dangerouslyAllowJsPopups: true
    },
    generateJWT: async () => {
      try {
        const res = await iterableJwtAuthentication();
        const token = res.data.jwt;
        return token;
      } catch (error) {
        console.error("Error during Iterable JWT generation:", error);
        return null;
      }
    }
  };

  try {
    const { setEmail } = initializeWithConfig(initializeParams);
    const { request, triggerDisplayMessages } = getInAppMessages(
      {
        count: 20,
        packageName: config.iterable.packageName
      },
      { display: "immediate" }
    );
    await setEmail(email);
    const { data } = await request();

    if (data.inAppMessages) {
      data.inAppMessages.forEach(message => {
        const trackingProperties = {
          campaignId: message.campaignId,
          messageId: message.messageId,
          isRead: message.read
        };

        analytics.track(AnalyticsEvents.label.iterable.trackMessageDelivered, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.alertShown,
          label: AnalyticsEvents.label.iterable.trackMessageDelivered,
          property: JSON.stringify(trackingProperties),
          value: "",
          context: "Iterable Messaging"
        });
      });
      await triggerDisplayMessages(data.inAppMessages);
    }
  } catch (error) {
    return null;
  }
}
